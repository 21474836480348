/**
 * Generates an ID string that is unique across entire project.
 * 
 * @param {string} prefix Prefix of the generated ID.
 */
const nextId = (function() {
  let id = 0;
  return function(prefix = "id-") {
    return prefix + id++;
  };
})();

export default nextId;
