export default [
  {
    title: "Life changing!",
    text: "My friend introduced this app to me at the beginning of the week and I have done over 5 hours of revision already!! My outlook on revision has completely changed and Flora has revolutionised the way I work!!",
    author: "Martii Moo",
    country: "gb"
  },
  {
    title: "Good to spend less time on your phone and saving the environment",
    text: "I’m enjoying this app because I can focus on studying or having breaks off my phone. Also I can spend more time with family and friends without getting distracting.",
    author: "Maddy_rose_forever",
    country: "gb"
  },
  {
    title: "FIRST EVER REVIEW COS THIS APP IS WORTH IT",
    text: "Very well put together, I love thee idea of stepping away from technology and being able to do so with friends. I also highly admire the contributions and major theme of planting trees and the option to plant a REAL Tree!!! Amazing.",
    author: "OhNah21",
    country: "us"
  },
  {
    title: "Honestly a wonderful app",
    text: "I never write reviews unless I truly love an app, and this is no exception. It’s such a simple yet effective concept. I was skeptical at first but after using it for the first time, it was able to help me concentrate almost instantly.",
    author: "Mighty G 444",
    country: "ca"
  },
  {
    title: "Great animation, nice graphic",
    text: "What a nice app to manage time!",
    author: "Jasonhe6060",
    country: "au"
  },
  {
    title: "Absolute gem for productivity!!",
    text: "I’m currently in preparation for college next year and I have to say, this app is stellar for keeping me motivated! I’m actually getting a bit addicted to making sure my garden is full of new little plants. But it’s the good kind of addiction. I plan to continue using this app to raise my grades and stay focused on my goals. Would recommend 100% to anyone looking to stay on task!",
    author: "Fifiand",
    country: "au"
  },
  {
    title: "Awesome",
    text: "Great way to keep me focused. I’m actively avoiding my phone to keep my trees alive.",
    author: "MilaanW",
    country: "us"
  },
  {
    title: "Encourages me to be productive!",
    text: "This app did everything I expected it to. I luv how you can grow many plant varieties on the “Grand Tour.” If you work hard, your garden is cute and vibrant! Super rewarding.",
    author: "__TOGUARA__",
    country: "nz"
  },
  {
    title: "Best app to stop procrastination!",
    text: "I never do reviews but this was the best app to help me through my A Levels and would recommend to anyone doing any type of exams if you want to get off your phone! One of the best apps I’ve used.",
    author: "013876463820",
    country: "gb"
  },
  {
    title: "實用度超高",
    text: "非常非常喜歡這個 app，都靠他來專心讀書，已經把偉大航道跟熱帶雨林都破解完畢，超有成就感！值得使用！可以讓你專心又能破關，何樂而不為？",
    author: "Jennychenlin",
    country: "tw"
  },
  {
    title: "Fun and cute!",
    text: "This app is super cool and helps me to stay off my device when I’m supposed to be doing something! I can also plant a tree with my friend so we can both be productive. Super cute and fun app!",
    author: "KMTPeanut",
    country: "ca"
  },
  {
    title: "很棒的軟體",
    text: "我是個仍在求學中學生。Flora 真的是個很棒的軟體。有了它，我慢慢開始喜歡讀書，並在結束時獲得很大的成就感。我將 Flora 推薦給我身邊的兩位朋友，並在隔天分享當天讀書的情況和自己的花園中有了什麼植物。我真的很高興我能遇見 Flora，希望更多的人能夠和我一同體驗這個超棒的軟體！",
    author: "Carina 茹茹",
    country: "tw"
  },
  {
    title: "Love this app!",
    text: "What a great idea!!! Combine study with environmental conservation... Will definitely be more productive with this app!",
    author: "BlackBear from T.U.P.",
    country: "au"
  },
  {
    title: "Best app ever",
    text: "I’m so bad at studying and focusing and I am the queen of procrastination. But honestly, this app is the only thing that’s ever got me to focus. Worth paying for a thousand times over! And you help plant REAL TREES! Studying and saving the planet? Winner",
    author: "Hun818",
    country: "gb"
  },
  {
    title: "Revolutionary",
    text: "My friend at university recommended this app to me to help with focusing on studying, and honestly, I owe her and the app developers my life and my degree when I get it.",
    author: "Katie M J",
    country: "gb"
  },
  {
    title: "Love this app",
    text: "Being able to actually see the “fruits” of my labor is extremely rewarding and helpful, especially when it comes to focusing on something as difficult as studying for big exams (MCAT studying for me).",
    author: "jpsulli211",
    country: "us"
  }
];
