import styles from "./Cta.module.scss";
import "media/icon-font/icon-font.css";

import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import { Row, Col, Button } from "react-bootstrap";

export default function Cta({
  linkIdPrefix,
  shadow,
  iosVariant,
  onIosClicked,
  androidVariant,
  onAndroidClicked,
  chromeVariant,
  onChromeClicked,
}) {
  return (
    <Row
      className={classNames(
        styles.component,
        "justify-content-center align-items-center text-center"
      )}
      data-aos="fade-up"
    >
      <Col md="auto" className="px-0 px-md-3">
        <Button
          id={`${linkIdPrefix}-ios`}
          className={classNames(
            styles.ctaBtn,
            "mb-3",
            shadow ? "boxShadow" : ""
          )}
          href="https://apps.apple.com/app/flora-focus-habit-tracker/id1225155794"
          variant={iosVariant}
          size="lg"
          onClick={onIosClicked}
        >
          <span className="icon icon-apple mr-2" />
          <FormattedMessage
            id="Cta.appleStore"
            defaultMessage="Get on App Store"
          />
        </Button>
        <br />
        <Button
          id={`${linkIdPrefix}-android`}
          className={classNames(
            styles.ctaBtn,
            "mb-3",
            shadow ? "boxShadow" : ""
          )}
          href="#"
          variant={androidVariant}
          size="lg"
          onClick={onAndroidClicked}
        >
          <span className="icon icon-google-play mr-2" />
          <FormattedMessage
            id="Cta.googlePlay"
            defaultMessage="Get on Google Play"
          />
        </Button>
      </Col>
      <Col
        className={classNames(
          styles.ctaBtnSep,
          "d-none d-md-block px-0 display-4"
        )}
        md="auto"
      >
        |
      </Col>
      <Col md="auto" className="px-0 px-md-3">
        <Button
          id={`${linkIdPrefix}-chrome`}
          className={classNames(
            styles.ctaBtn,
            "mb-3",
            shadow ? "boxShadow" : ""
          )}
          href="#"
          variant={chromeVariant}
          size="lg"
          onClick={onChromeClicked}
        >
          <span className="icon icon-google-chrome mr-2" />
          <FormattedMessage
            id="Cta.chromeExt"
            defaultMessage="Chrome Extension"
          />
        </Button>
      </Col>
    </Row>
  );
}
