import styles from "./Share.module.scss";
import "media/icon-font/icon-font.css";

import React, { useContext } from "react";
import classNames from "classnames";

import { Button } from "react-bootstrap";

import { AppContext } from "index.js";

export default function Share({
  linkIdPrefix,
  facebookVariant,
  twitterVariant,
}) {
  const appContext = useContext(AppContext);

  return (
    <div className={styles.component}>
      <Button
        id={`${linkIdPrefix}-facebook`}
        className={classNames(styles.shareBtn, "mr-3")}
        href={encodeURI(
          `https://www.facebook.com/share.php?u=https://flora.appfinca.com${appContext.urlPrefix}&title=Flora`
        )}
        target="_blank"
        rel="noopener noreferrer"
        variant={facebookVariant}
      >
        <span className="icon icon-facebook mr-2" />
      </Button>
      <Button
        id={`${linkIdPrefix}-twitter`}
        className={classNames(styles.shareBtn)}
        href={encodeURI(
          `https://twitter.com/intent/tweet?text=${appContext.intl.formatMessage(
            {
              id: "Share.twitter",
              defaultMessage:
                "Cute app helps put down the phone and get things done:\n",
            }
          )}&url=https://flora.appfinca.com${appContext.urlPrefix}`
        )}
        target="_blank"
        rel="noopener noreferrer"
        variant={twitterVariant}
      >
        <span className="icon icon-twitter mr-2" />
      </Button>
    </div>
  );
}
