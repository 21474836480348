import styles from "./Home.module.scss";
import "media/icon-font/icon-font.css";
import logo from "media/icon.svg";
import plant from "media/plant.png";
import kill from "media/kill.png";
import tour from "media/tour.png";
import friends from "media/friends.png";
import todo from "media/todo.png";
import garden from "media/garden.png";
import price from "media/price.svg";
import care from "media/care.svg";
import flagUS from "media/flags/us.svg";
import flagCA from "media/flags/ca.svg";
import flagGB from "media/flags/gb.svg";
import flagAU from "media/flags/au.svg";
import flagNZ from "media/flags/nz.svg";
import flagTW from "media/flags/tw.svg";

import Cta from "components/Cta";
import Share from "components/Share";
import reviews from "./reviews.js";

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import {
  Container,
  Navbar,
  Nav,
  Jumbotron,
  Row,
  Col,
  Modal,
  Form,
  Button,
  Alert,
  Badge,
  Carousel,
} from "react-bootstrap";

import "aos/dist/aos.css";
import AOS from "aos";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import nextId from "utils/nextId.js";
import { AppContext } from "index.js";

const flagMap = {
  us: flagUS,
  ca: flagCA,
  gb: flagGB,
  au: flagAU,
  nz: flagNZ,
  tw: flagTW,
};

styles.sm = parseInt(styles.sm.slice(0, -2));
styles.md = parseInt(styles.md.slice(0, -2));
styles.lg = parseInt(styles.lg.slice(0, -2));
styles.xl = parseInt(styles.xl.slice(0, -2));

export default function Home() {
  const appContext = useContext(AppContext);

  const navbarId = nextId();
  let realTreeCountUpStarted = false;

  // Set up lead modals & form
  const [lead, setLead] = useState();
  const [leadModalShown, setLeadModalShown] = useState(false);
  const [leadFormValidated, setLeadFormValidated] = useState(false);
  const onAndroidCtaClicked = () => {
    setLead("android");
    setLeadModalShown(true);
  };
  const onChromeCtaClicked = () => {
    setLead("chrome");
    setLeadModalShown(true);
  };
  const onLeadFormSubmit = (e) => {
    const form = e.currentTarget;
    setLeadFormValidated(true);
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    setLeadModalShown(false);

    setTimeout(() => {
      setConfirmModalShown(true);
    }, 700);
  };
  const [confirmModalShown, setConfirmModalShown] = useState(false);

  // Set up review carousel
  const [gridSize, setGridSize] = useState([1, 1]); // [row, col]
  useEffect(() => {
    function sync() {
      if (window.innerWidth >= styles.xl) setGridSize([4, 2]);
      else if (window.innerWidth >= styles.lg) setGridSize([3, 2]);
      else if (window.innerWidth >= styles.md) setGridSize([2, 2]);
      else setGridSize([1, 2]);
    }
    window.addEventListener("resize", sync);
    sync();
    return () => window.removeEventListener("resize", sync);
  }, []);
  // Rotate `reviews` such that the center review is written in the current locale
  for (let i = 0; i < reviews.length; i++) {
    if (reviews[0].country !== appContext.currentLocale.split(/[-_]/)[1]) {
      reviews.push(reviews.shift());
    }
  }
  let reviewsPerPage = gridSize[0] * gridSize[1];
  for (let i = 0; i < Math.floor(reviewsPerPage / 2); i++) {
    reviews.unshift(reviews.pop());
  }
  let carouselItems = [];
  for (let i = 0; i < Math.floor(reviews.length / reviewsPerPage); i++) {
    let page = [];
    for (let j = 0; j < reviewsPerPage; j++) {
      page.push(reviews[i * reviewsPerPage + j]);
    }
    carouselItems.push(page);
  }
  const onReviewItemChanged = () => {
    window.dataLayer.push({
      event: "review-item-changed",
    });
  };

  // Set up animation
  useEffect(() => {
    // delay init. until the browser has painted so AOS can measure the element sizes correctly
    setTimeout(() => {
      AOS.init({
        duration: 600,
        // delay: 300,
        once: true,
      });
    }, 500);
  }, []);

  return (
    <div className={styles.component}>
      <header className={styles.header}>
        <div className={styles.headerCover} />
        <Container className={styles.headerFg}>
          <Navbar className="px-0" expand="md" variant="light">
            <Navbar.Brand
              as={Link}
              to="/"
              className={classNames(styles.navbarBrand, "mr-5")}
            >
              <img
                alt="Flora"
                src={logo}
                className={classNames(styles.navbarLogo, "mr-1 mb-1")}
              />
              {" Flora"}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={navbarId} />
            <Navbar.Collapse id={navbarId}>
              <Nav className={classNames(styles.nav, "mr-auto")}>
                <Nav.Link className="mr-2" href="#features">
                  <FormattedMessage
                    id="Home.nav.features"
                    defaultMessage="FEATURES"
                  />
                </Nav.Link>
                <Nav.Link className="mr-2" href="#real-trees">
                  <FormattedMessage
                    id="Home.nav.trees"
                    defaultMessage="TREES"
                  />
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to={`${appContext.urlPrefix}faq`}
                  className="mr-2"
                >
                  <FormattedMessage
                    id="Home.nav.support"
                    defaultMessage="SUPPORT"
                  />
                </Nav.Link>
              </Nav>
              <Nav className="mt-3 mt-md-0">
                <Share
                  linkIdPrefix="share-top"
                  facebookVariant="success"
                  twitterVariant="success"
                />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Jumbotron
            className={classNames(
              styles.jumbotron,
              "p-3 p-md-2 mb-9 mb-md-7 mb-xl-8"
            )}
          >
            <Container className="text-center">
              <Row className="justify-content-center">
                <Col>
                  <h1 className={styles.headerText}>
                    <FormattedMessage
                      id="Home.head.title"
                      defaultMessage="Green focus."
                    />
                  </h1>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="10" lg="8" xl="6">
                  <p className={classNames(styles.headerText, "mb-md-4")}>
                    <FormattedMessage
                      id="Home.head.subtitle"
                      defaultMessage="Flora is a new way to stay off your phone, clear to-do lists, and build positive, life-changing habits. Whenever you want to make progress toward your goals, grow trees in Flora<em>!</em>"
                      values={{ em: (msg) => <em>{msg}</em> }}
                    />
                  </p>
                </Col>
              </Row>
              <Cta
                linkIdPrefix="cta-top"
                iosVariant="black"
                androidVariant="black-30"
                onAndroidClicked={onAndroidCtaClicked}
                chromeVariant="black-30"
                onChromeClicked={onChromeCtaClicked}
              ></Cta>
            </Container>
          </Jumbotron>
        </Container>
      </header>

      <Modal
        show={leadModalShown}
        onHide={() => setLeadModalShown(false)}
        centered
      >
        <Modal.Header className="border-bottom-0" closeButton>
          <Modal.Title>
            <FormattedMessage
              id="Home.leadModal.title"
              defaultMessage="Coming soon..."
              values={{
                em: (msg) => <em>{msg}</em>,
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Form
          id="lead-form"
          action="https://docs.google.com/forms/d/e/1FAIpQLScPu1kNJegBjg8a2g_lLK4uCmXRYn4eQ8fVCOq-itO1bzqjgQ/formResponse"
          target="lead_iframe"
          method="post"
          noValidate
          validated={leadFormValidated}
          onSubmit={onLeadFormSubmit}
        >
          <Modal.Body>
            <p>
              <FormattedMessage
                id="Home.leadModal.text"
                defaultMessage="This version of Flora is on the way. Please stay tuned. If you would like to be notified when it becomes available, leave your email address below:"
              />
            </p>

            <Form.Group controlId="lead-form-email">
              {/* <Form.Label>Email address</Form.Label> */}
              <Form.Control
                required
                type="email"
                placeholder={appContext.intl.formatMessage({
                  id: "Home.leadModal.email",
                  defaultMessage: "Enter email...",
                })}
                name="entry.1282053855"
              />
              <Form.Control.Feedback type="invalid">
                <FormattedMessage
                  id="Home.leadModal.error"
                  defaultMessage="Please provide a valid email address."
                />
              </Form.Control.Feedback>
              {/* <Form.Text className="text-muted">
                We’ll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>
            <Form.Group className="d-none" controlId="lead-form-platform">
              <Form.Control
                type="text"
                name="entry.1851332349"
                value={lead}
                readOnly
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button
              className="mr-auto"
              variant="outline-primary-dark"
              onClick={() => setLeadModalShown(false)}
            >
              <FormattedMessage
                id="Home.leadModal.close"
                defaultMessage="Close"
              />
            </Button> */}
            <Button variant="primary" type="submit" size="lg" block>
              <FormattedMessage
                id="Home.leadModal.submit"
                defaultMessage="Get Notified when Available"
              />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <iframe
        title="Flora v3 Lead (Responses)"
        name="lead_iframe"
        className="d-none"
        aria-hidden="true"
      ></iframe>
      <Modal
        show={confirmModalShown}
        onHide={() => setConfirmModalShown(false)}
        centered
      >
        <Modal.Header className="border-bottom-0" closeButton>
          <Modal.Title>
            <FormattedMessage
              id="Home.confirmModal.title"
              defaultMessage="Thank You"
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <FormattedMessage
              id="Home.confirmModal.text"
              defaultMessage="We will get back to you as soon as possible."
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            size="lg"
            block
            onClick={() => setConfirmModalShown(false)}
          >
            <FormattedMessage
              id="Home.confirmModal.close"
              defaultMessage="Close"
            />
          </Button>
        </Modal.Footer>
      </Modal>

      <article className={styles.article}>
        <section id="features" className={styles.features}>
          <Container className="mb-5">
            <h2 className={styles.sectionTitle}>
              <FormattedMessage
                id="Home.focus.title"
                defaultMessage="Grow trees to stay calm and focused."
              />
            </h2>
            <Row className="justify-content-md-center mb-3">
              <Col md={10} lg={8} xl={6}>
                <p>
                  <FormattedMessage
                    id="Home.focus.text"
                    defaultMessage="Have trouble putting down your phone? Flora blocks distracting apps, such as Facebook, Instagram or games, in a pleasant way to help you focus on what’s more important in real life."
                  />
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                className="d-flex flex-column align-items-center mb-5"
                md={4}
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img className={styles.screenshot} src={plant} alt="Step 1" />
                <p className={styles.howText}>
                  <FormattedMessage
                    id="Home.todo.1"
                    defaultMessage="1. Select a focus duration, and then press <ui>Start</ui> to grow a tree."
                    values={{ ui: (msg) => <span className="ui">{msg}</span> }}
                  />
                </p>
              </Col>
              <Col
                className="d-flex flex-column align-items-center mb-5"
                md={4}
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <img className={styles.screenshot} src={kill} alt="Step 2" />
                <p className={styles.howText}>
                  <FormattedMessage
                    id="Home.todo.2"
                    defaultMessage="2. If you leave the app for social media or games, the tree will die."
                  />
                </p>
              </Col>
              <Col
                className="d-flex flex-column align-items-center mb-5"
                md={4}
                data-aos="zoom-in-up"
                data-aos-delay="600"
              >
                <img className={styles.screenshot} src={tour} alt="Step 3" />
                <p className={styles.howText}>
                  <FormattedMessage
                    id="Home.todo.3"
                    defaultMessage="3. Discover new trees by completing focus sessions and tasks."
                  />
                </p>
              </Col>
            </Row>
            <h2 className={classNames(styles.sectionTitle, "pt-4")}>
              <FormattedMessage
                id="Home.friends.title"
                defaultMessage="Double efficiency with friends."
              />
            </h2>
            <Row className="justify-content-md-center mb-3">
              <Col
                md={8}
                lg={7}
                xl={6}
                className="d-flex flex-column align-items-center"
              >
                <img
                  className="img-fluid mb-5"
                  src={friends}
                  alt="Friends"
                  data-aos="zoom-in-up"
                  data-aos-delay="300"
                />
              </Col>
              <div className="w-100"></div>
              <Col md={10} lg={8} xl={6}>
                <p>
                  <FormattedMessage
                    id="Home.friends.text"
                    defaultMessage="Things get even more interesting when you challenge your friends to plant trees together—if you successfully stay off the phones together, each of you will win an additional tree. You can also chat and share your progress to motivate each other and keep everyone on track. Teamwork makes the dream work<em>!</em>"
                    values={{ em: (msg) => <em>{msg}</em> }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="Home.friends.original"
                    defaultMessage="Flora is the original app for joint tree planting and delivers the best features that help you and your friends be productive."
                  />
                </p>
              </Col>
            </Row>
          </Container>

          <Alert
            className={classNames(styles.todo, "box-shadow pb-5 mb-5")}
            variant="primary"
            data-aos="slide-left"
            data-aos-delay="300"
          >
            <Container>
              <h2 className={styles.sectionTitle}>
                <FormattedMessage
                  id="Home.todo.title"
                  defaultMessage="Get organized with a better to-do list. <badge>NEW</badge>"
                  values={{
                    badge: (msg) => (
                      <Badge className="ml-2" pill variant="primary">
                        {msg}
                      </Badge>
                    ),
                  }}
                />
              </h2>
              <Row className="justify-content-center align-items-center">
                <Col
                  className="d-flex flex-column align-items-center pb-5 pb-md-0 order-md-2"
                  md="5"
                  lg="4"
                >
                  <img className={styles.screenshot} src={todo} alt="To-dos" />
                </Col>
                <Col className="order-md-1" md="7" lg="6" xl="5">
                  <p>
                    <FormattedMessage
                      id="Home.todo.text"
                      defaultMessage="Flora is a to-do list, and more. Simply create a to-do item and set a reminder, then you will never forget a thing."
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="Home.todo.more"
                      defaultMessage="Even better, you can tag your trees with a to-do item to easily track the progress towards each of your life goals. No matter if your goal is daily, weekly, or monthly, Flora will help you follow it through and achieve it."
                    />
                  </p>
                </Col>
              </Row>
            </Container>
          </Alert>
          <Container>
            <h2 className={styles.sectionTitle}>
              <FormattedMessage
                id="Home.habit.title"
                defaultMessage="Life-changing habits made easy."
              />
            </h2>
            <Row
              className="justify-content-center align-items-center"
              data-aos="slide-right"
              data-aos-delay="300"
            >
              <Col
                className="d-flex flex-column align-items-center pb-5 pb-md-0"
                md="5"
                lg="4"
              >
                <img className={styles.screenshot} src={garden} alt="Garden" />
              </Col>
              <Col md="7" lg="6" xl="5">
                <p>
                  <FormattedMessage
                    id="Home.habit.text1"
                    defaultMessage="Enjoy a rewarding daily habit routine. If you grow trees regularly, you will earn yourself a beautiful, vibrant garden. Take a look at it and feel proud of yourself<em>!</em> Each tree records a step towards your healthy life."
                    values={{
                      em: (msg) => <em>{msg}</em>,
                    }}
                  />
                </p>
                <p className="pb-5">
                  <FormattedMessage
                    id="Home.habit.text2"
                    defaultMessage="You can also view the daily, weekly, or monthly statistics of your activities in Flora to find opportunities to improve your time-management and planning skills."
                  />
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          id="real-trees"
          className={classNames(styles.realTree, "pt-5 pb-9")}
        >
          <div className={styles.realTreeCover}></div>
          <Container>
            <h2 className={styles.sectionTitle}>
              <FormattedMessage
                id="Home.realTree.title"
                defaultMessage="Real trees, real impact."
              />
            </h2>
            <Row className="justify-content-center align-items-center mb-5">
              <Col xs={{ span: 5, order: 2 }} md={{ span: 3, order: 1 }}>
                <img className="img-fluid" src={price} alt="Price Service" />
              </Col>
              <Col
                xs={{ span: 12, order: 1 }}
                md={{ span: 4, order: 2 }}
                className="text-center mt-3"
              >
                <h1>
                  <CountUp end={105638} duration={1.5} separator=",">
                    {({ countUpRef, start }) => (
                      <VisibilitySensor
                        onChange={(isVisible) => {
                          if (!realTreeCountUpStarted && isVisible) {
                            start();
                            realTreeCountUpStarted = true;
                          }
                        }}
                        delayedCall
                      >
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <br />
                </h1>
                <p>
                  <FormattedMessage
                    id="Home.realTree.count"
                    defaultMessage="real trees planted by Flora users."
                  />
                </p>
              </Col>
              <Col
                className="mt-2"
                xs={{ span: 5, order: 3 }}
                md={{ span: 3, order: 3 }}
              >
                <img className="img-fluid" src={care} alt="Care Service" />
              </Col>
            </Row>
            <div className="w-100"></div>
            <Row className="justify-content-center">
              <Col md="8" lg="7" xl="6">
                <p>
                  <FormattedMessage
                    id="Home.realTree.text1"
                    defaultMessage="Flora proudly offers the opt-in Price and Care services that let you plant real trees on the earth when you kill or successfully grow a tree in the app. So, you can save the earth and lives while simultaneously improving yourself."
                  />
                </p>
                <p className="pb-md-5">
                  <FormattedMessage
                    id="Home.realTree.text2"
                    defaultMessage="We partner with tree-planting organizations, such as the <a1>Trees for the Future</a1> and <a2>Eden Reforestation Projects</a2>, that work with local communities in Africa, East Asia, and South America to plant fast-growing soil rebuilding trees, fruit trees that diversify nutrition, and trees that can provide forage and fuel-wood. All these trees help make the earth greener and provide long-term financial aid to families who need it most."
                    values={{
                      a1: (msg) => (
                        <a
                          className="text-primary-light"
                          id="real-trees-tft"
                          href="https://trees.org/sponsor/flora"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {msg}
                        </a>
                      ),
                      a2: (msg) => (
                        <a
                          className="text-primary-light"
                          id="real-trees-eden"
                          href="https://edenprojects.org/user/flora"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {msg}
                        </a>
                      )
                    }}
                  />
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={classNames(styles.ratingSection, "pb-5")}>
          <div className={styles.ratingHeader}>
            <h1>4.7+</h1>
            <h3>
              <span className="icon icon-star mr-1 text-warning" />
              <span className="icon icon-star mr-1 text-warning" />
              <span className="icon icon-star mr-1 text-warning" />
              <span className="icon icon-star mr-1 text-warning" />
              <span className="icon icon-star-half mr-1 text-warning" />
            </h3>
          </div>
          <div className="position-relative">
            <Container className="p-0" fluid>
              <h2 className={classNames(styles.sectionTitle, "pt-5 pb-3")}>
                <FormattedMessage
                  id="Home.rating.title"
                  defaultMessage="rated by 2M+ global users."
                />
              </h2>
              <Carousel
                indicators={false}
                interval={null}
                prevIcon={
                  <h1 className=" display-1">
                    <span className="icon icon-chevron-left-circle text-primary" />
                  </h1>
                }
                nextIcon={
                  <h1 className=" display-1">
                    <span className="icon icon-chevron-right-circle text-primary" />
                  </h1>
                }
                onSelect={onReviewItemChanged}
              >
                {carouselItems.map((carouselItem, cIdx) => (
                  <Carousel.Item key={cIdx}>
                    <div className="d-inline-block mx-auto">
                      <div
                        className={classNames(styles.carouselItem)}
                        style={{
                          width: (280 + 16) * gridSize[0] + "px",
                        }}
                      >
                        {carouselItems[cIdx].map((reviewItem, rIdx) => (
                          <div
                            key={rIdx}
                            style={{
                              width: 280 + "px",
                              margin: 8 + "px",
                            }}
                            className={classNames(
                              styles.reviewItem,
                              "d-flex box-shadow"
                            )}
                          >
                            <h4 className="">
                              <span className="icon icon-format-quote-open text-info-dark" />
                            </h4>
                            <div className="w-100">
                              <p className="font-weight-bold pt-3">
                                {reviewItem.title}
                              </p>
                              <p>{reviewItem.text}</p>
                              <p className="text-right">
                                <span className="mr-2">&ndash;</span>
                                {reviewItem.author}
                                <img
                                  className={classNames(
                                    styles.reviewCountry,
                                    "ml-2 mb-1"
                                  )}
                                  src={flagMap[reviewItem.country]}
                                  alt={reviewItem.title}
                                />
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Container>
          </div>
        </section>

        <section className={classNames(styles.cta, "pt-3 pb-6")}>
          <div className={styles.ctaCover} />
          <Container className="position-relative">
            <h1 className={styles.sectionTitle}>
              <FormattedMessage
                id="Home.cta.title"
                defaultMessage="Get Flora now. It’s free<em>!</em>"
                values={{ em: (msg) => <em>{msg}</em> }}
              />
            </h1>
            <Cta
              linkIdPrefix="cta-bottom"
              iosVariant="black"
              androidVariant="black-30"
              onAndroidClicked={onAndroidCtaClicked}
              chromeVariant="black-30"
              onChromeClicked={onChromeCtaClicked}
            ></Cta>
          </Container>
        </section>
      </article>

      <footer id="footer" className={styles.footer}>
        <Container className="pt-6 pb-4">
          <Row className="justify-content-center">
            <Col className="pb-5" md="5">
              <h4>
                <FormattedMessage
                  id="Home.footer.connect.title"
                  defaultMessage="Let’s stay connected."
                />
              </h4>
              <p>
                <FormattedMessage
                  id="Home.footer.connect.text"
                  defaultMessage="Having questions? We love to help. Please visit our <support>Support</support> page to see the FAQ and their solutions, or drop us an email at <email>support@flora.appfinca.com</email>. We will get back to you as soon as possible."
                  values={{
                    support: (msg) => (
                      <Link id="support-faq" to={`${appContext.urlPrefix}faq`}>
                        {msg}
                      </Link>
                    ),
                    email: (msg) => (
                      <a id="support-mailto" href={`mailto:${msg}`}>
                        {msg}
                      </a>
                    ),
                  }}
                />
              </p>
            </Col>
            <Col className="pb-5" md="5">
              <h4>
                <FormattedMessage
                  id="Home.footer.about.title"
                  defaultMessage="We’re committed to change."
                  values={{ em: (msg) => <em>{msg}</em> }}
                />
              </h4>
              <p>
                <FormattedMessage
                  id="Home.footer.about.text"
                  defaultMessage="Flora is developed by AppFinca Inc., a group of young, energetic programmers and environmental enthusiasts who believe everyone can change the world by using better software."
                />
              </p>
              <p>
                <FormattedMessage
                  id="Home.footer.share"
                  defaultMessage="Share Flora with your friends to grow trees together:"
                />
              </p>
              <Share
                linkIdPrefix="share-bottom"
                facebookVariant="outline-white"
                twitterVariant="outline-white"
              />
            </Col>
          </Row>
          <Row>
            <Col className={classNames(styles.legal, "text-center")}>
              <small>
                {new Date().getFullYear()} &copy; AppFinca Inc.
                <span className="d-none d-md-inline"> | </span>
                <br className="d-inline d-md-none" />
                <FormattedMessage
                  id="Home.footer.legal"
                  defaultMessage="<privacy>Privacy Policy</privacy> | <terms>Terms of Service</terms> | <ack>Acknowledgements</ack>"
                  values={{
                    privacy: (msg) => (
                      <Link
                        id="support-privacy"
                        to={`${appContext.urlPrefix}privacy`}
                      >
                        {msg}
                      </Link>
                    ),
                    terms: (msg) => (
                      <Link
                        id="support-terms"
                        to={`${appContext.urlPrefix}terms`}
                      >
                        {msg}
                      </Link>
                    ),
                    ack: (msg) => (
                      <Link
                        id="support-ack"
                        to={`${appContext.urlPrefix}acknowledgements`}
                      >
                        {msg}
                      </Link>
                    ),
                  }}
                />
              </small>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}
